@import '~antd/dist/antd.css';

#root {
  height: 100%;
}

.App {
  /* text-align: center; */
  height: 100%;
}

.ant-layout {
  height: 100%;
}

.header-top-left-logo {
  width: 100%;
  text-align: center;
  padding: 5px;
}

.header-top-left-logo img {
  height: 50px !important;
}

.ant-menu-inline-collapsed .ant-menu-item-icon {
  vertical-align: inherit !important;
}

.page-title .add-event {
  padding: 5px 30px;
}

.page-title-head {
  font-size: 1.2rem;
  font-weight: bold;
}

.action-button {
  padding: 5px 15px;
}

.notification-popover {
  min-width: 350px;
  max-width: 350px;
  max-height: 100vh;
  overflow-x: hidden;
  overflow-y: hidden;
  width: auto;
}

.notification-popover1 {
  max-height: 90vh;
  overflow-y: auto;
}

.notification-popover .ant-popover-title {
  padding: 10px 16px 10px;
}

.ant-picker-input>input[disabled],
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-input[disabled],
.ant-input-number-disabled {
  color: rgba(0, 0, 0, 0.75) !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.name_nowrap .ant-table-tbody>tr>td:first-child {
  white-space: nowrap;
}

.name_nowrap .ant-table-thead>tr>th:last-child {
  min-width: 95px;
}